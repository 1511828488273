<template>
    <!-- 电脑端导航 -->
    <div :class="isShow == 1 ? ' wraper isShow' : isShow == 2 ?' wraper isHide' : 'wraper'" ref="headerRef">
        <div class="top_box">
            <div class="top_box_content pc_top_box clearfix max_small_box ">
                <template v-for="(item, index) in toplist" :key="index">
                    <router-link v-if="item.tip=='local'" :to="item.value">{{item.title}}</router-link>
                    <a v-if="item.tip=='exterior'" :href="item.value" target="_blank">{{item.title}}</a>
                </template>
                <!-- <router-link to="/statement">公正性承诺</router-link>
                <router-link to="/toll">收费标准</router-link>
                <router-link to="/join">加入我们</router-link>
                <a href="http://mail.gddqt.com/" target="_blank">中心邮箱</a> -->
                <div class="search">
                    <input type="text" name="" id="" v-model="searchValue" placeholder="搜索" @keyup.enter="onSubmit">
                    <router-link v-if="searchValue!=''" class="text iconfont" :to="`/search/${searchValue}`">
                        <img style="width: 100%" src="@/assets/images/icon.png" alt="">
                    </router-link>
                    <!-- <router-link v-if="searchValue!=''" class="text iconfont" :to="`/search/${searchValue}`">&#xe668;</router-link> -->
                    <!-- <p v-else class="text iconfont">&#xe668;</p> -->
                    <img v-else class="text" src="@/assets/images/icon.png" alt="">
                </div>
            </div>
        </div>
        <div class="under_box">
            <div class="under_box_content max_small_box">
                <router-link to="/" class="under_box_content_logo">
                    <img src="@/assets/images/logo.png" alt="">
                </router-link>
                <div class="under_box_content_nav">
                    <div 
                        class='under_box_content_nav_item' 
                        v-for="(item, index) in list" 
                        :key="index" 
                    >   
                        <router-link v-if="item.page == '/' " to='/'>
                            <p :class="item.page == url.urlLink ? 'active title' : 'title'" >{{item.name}}</p>
                        </router-link>
                        <router-link v-else-if="item.second_nav[0] && item.page != '/service_industry'" :to="`${item.page}/${item.second_nav[0].toLink}/${item.second_nav[0].id}${item.page}`">
                            <p :class="item.page == url.urlLink ? 'active title' : 'title'" >{{item.name}}</p>
                        </router-link>
                        <router-link v-else :to="`/industry_list/${item.second_nav[0].id}/0`">
                            <p  :class="item.page == url.urlLink ? 'active title' : 'title'" >{{item.name}}</p>
                        </router-link>
                        
                        <div v-if="item.page != '/service_industry' " class="second_navigation">
                        <template v-for="(list, listIndex) in item.second_nav" :key="listIndex">
                            <a class="name" v-if="list.toLink.split(':')[0] == 'https' || list.toLink.split(':')[0] == 'http'"  :href="list.toLink" target="view_window">
                                <span  >{{list.name}}</span>
                            </a>
                            <router-link class="name" v-else  :to="`${list.page_id}/${list.toLink}/${list.id}${list.page_id}`">
                                <span  >{{list.name}}</span>
                            </router-link>
                        </template>
                        </div>
                        <div v-if="item.page == '/service_industry' " class="second_navigation_two">
                            <div class="one" :class="`boxwidth${listIndex}`" v-for="(list, listIndex) in item.second_nav" :key="listIndex">
                                <!-- <p class="one_title">{{list.name}}</p> -->
                                <router-link class="one_title" v-if="list.id==51" :to="`/industry_list/${list.id}/${listIndex}/0`">{{list.name}}</router-link>
                                <router-link class="one_title" v-else :to="`/industry_list/${list.id}/${listIndex}`">{{list.name}}</router-link>
                                <div class="line"></div>
                                <div class="box" v-if="list.id!=50 && list.id!=51">
                                    <router-link :to="`/industry/${listT.id}${list.page_id}/${list.id}/${listIndexT}`"  v-for="(listT, listIndexT) in list.third_nav" :key="listIndexT" class="box_name">
                                        <p style="padding-left: 10px;">{{listT.name}}</p>
                                    </router-link>
                                </div>
                                <div class="box" v-if="list.id==50">
                                    <router-link :to="`/industry/${listT.id}${list.page_id}/${list.id}/${listIndexT}`"  v-for="(listT, listIndexT) in list.third_nav" :key="listIndexT" class="box_name_two">
                                        <p style="padding-left: 10px;">{{listT.name}}</p>
                                    </router-link>
                                </div>
                                <div class="box" v-if="list.id==51">
                                    <router-link :to="`/industry_list/${list.id}/${listIndex}/${listIndexT}`" v-for="(listT, listIndexT) in list.third_nav" :key="listIndexT" class="box_name_two">
                                        <p style="padding-left: 10px;">{{listT.name}}</p>
                                    </router-link>
                                </div>
                            </div>
                            <!-- <div class="one">
                                <p class="one_title">服务</p>
                                <div class="line"></div>
                                <div class="box">
                                    <router-link :to="`/serviceDetail/${service.id}`" v-for="(service, index) in servicelist" :key="index" class="box_name">
                                        <p >{{service.name}}</p>
                                    </router-link>
                                    <p></p>
                                    <p></p>
                                </div>
                            </div> -->
                            <!-- <div class="one">
                                <p class="one_title">新兴业务</p>
                                <div class="line"></div>
                                <div class="box">
                                    <router-link :to="`/industry/${listT.id}${list.page_id}/${list.id}`"  v-for="(listT, listIndexT) in emergingList" :key="listIndexT" class="box_name">
                                        <p >{{listT.name}}</p>
                                    </router-link>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 手机端导航 -->
    <div class="small_nav_box">
        <div class="small_nav" ref="headerRef">
            <div class="top_box" ref="headerSmallRef">
                <div class="top_box_content">
                    <template v-for="(item, index) in toplist" :key="index">
                        <router-link v-if="item.tip=='local'" :to="item.value">{{item.title}}</router-link>
                        <a v-if="item.tip=='exterior'" :href="item.value" target="_blank">{{item.title}}</a>
                    </template>
                    <router-link to="/search/">搜索</router-link>
                    <!-- <router-link to="/statement">公正性承诺</router-link>
                    <router-link to="/toll">收费标准</router-link>
                    <router-link to="/join">加入我们</router-link>
                    <a href="http://mail.gddqt.com/" target="_blank">企业邮箱</a> -->
                </div>
            </div>
            <div class="under_box">
                <router-link to="/" class="left">
                    <img src="@/assets/images/logotwo.png" alt="">
                </router-link>
                <div class="right" @click="handleNavShow">
                    <!-- <p class="iconfont">&#xe61f;</p> -->
                    <img class="icon_img" src="@/assets/images/icon01.png" alt="">
                </div>
            </div>
        <div 
            class="select_nav_back" 
            v-show="smallNavShow" 
            :style="{ top: headerRefHeight + 'px'}"
             @click="handleNavShow"
        >
        </div>
        <div 
            :class=" smallNavShow == true ? 'select_nav_box select_nav_box_active' : 'select_nav_box' " 
            :style="{ top: headerRefHeight + 'px'}"
        >
            <div class="mobilemenu" v-if="list">
                <!-- 一级导航 -->
                <div class="first" 
                    :style="{ transform: 'translateX(' + FirstTranslateX + 'px)'}"
                >
                    <div class="item" 
                        v-for="(item, index) in list" 
                        :key="index" 
                    >
                        <div v-if="item.second_nav.length != 0" @click="gotoSecond(item.name, index)">
                            <p class="title">{{item.name}}</p>
                            <img class="icon_img" src="@/assets/images/icon_you.png" alt="">
                            <!-- <p class="iconfont icon">&#xe622;</p> -->
                        </div>
                        <div v-else-if="item.page == '/' ">
                            <router-link to="/">
                                <p class="title">{{item.name}}</p>
                                <p class="iconfont icon"></p>
                            </router-link>
                        </div>
                        <div v-else >
                            <p class="title">{{item.name}}</p>
                            <p class="iconfont icon"></p>
                        </div>
                    </div>
                </div> 
                <!-- 二级导航 -->
                <div class="second navSecond" 
                    :style="{ transform: 'translateX(' +SecondTranslateX + 'px)'}"
                >
                    <div class="top" @click="handleNavBack">
                            <img class="icon_img" src="@/assets/images/icon_zuo.png" alt="">
                        <!-- <p class="iconfont icon">&#xe622;</p> -->
                        <p class="title">{{secondNavValue}}</p>
                    </div>
                    <p class="nav_title">{{NavTitleValue}}</p>
                    
                    <div class="second_list_box" v-if=" list[NavTitleIndex].second_nav">
                        <div class="second_list"  v-for="(item, index) in list[NavTitleIndex].second_nav" :key="index" >
                            <div v-if="item.third_nav.lnegth == 0" @click="gotoThird(item.name, index)">
                                <p class="title">{{item.name}}</p>
                            <img class="icon_img" src="@/assets/images/icon_you.png" alt="">
                                <!-- <p class="iconfont icon">&#xe622;</p> -->
                            </div>
                            <div v-else-if="item.page_id == '/service_industry'" @click="gotoThird(item.name, index)">
                                <!-- <router-link :to="`/industry/${item.id}${item.page_id}`"> -->
                                <!-- </router-link> -->
                                <p class="title">{{item.name}}</p>
                            <img class="icon_img" src="@/assets/images/icon_you.png" alt="">
                                <!-- <p class="iconfont icon">&#xe622;</p> -->
                            </div>
                            <div v-else-if="item.toLink.split(':')[0] == 'https' || item.toLink.split(':')[0] == 'http'"  >
                                <a :href="item.toLink" target="view_window">
                                    <p class="title">{{item.name}}</p>
                                    <p class="iconfont icon"></p>
                                </a>
                            </div>
                            <div v-else>
                                <router-link :to="`${item.page_id}/${item.toLink}/${item.id}${item.page_id}`" @click="handleNavShow">
                                    <p class="title">{{item.name}}</p>
                                    <p class="iconfont icon"></p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 三级导航 -->
                <div class="thrid navThrid"
                    :style="{ transform: 'translateX(' +ThirdTtranslateX + 'px)'}"
                >
                    <div class="top" @click="handleNavBackSecond">
                            <img class="icon_img" src="@/assets/images/icon_zuo.png" alt="">
                        <!-- <p class="iconfont icon">&#xe622;</p> -->
                        <p class="title">{{NavTitleValue}}</p>
                    </div>
                    <p class="nav_title">{{thirdNavValue}}</p>
                    
                    <div class="thrid_list_box" v-if="list[NavTitleIndex].second_nav[NavTitleSecondIndex]">
                        <div class="thrid_list"  v-for="(item, index) in list[NavTitleIndex].second_nav[NavTitleSecondIndex].third_nav" :key="index" >
                            <router-link :to="`/industry/${item.id}${item.page_id}/${list[NavTitleIndex].second_nav[NavTitleSecondIndex].id}/${index}`" @click="handleNavShow">
                                <p class="title">{{item.name}}</p>
                            </router-link>
                            <p class="iconfont icon"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue';
import { onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { post, get } from '../../utils/request';

//电脑端导航处理函数
const navListEffect = () => {
    const router = useRouter();
    //导航列表
    const list = ref('')

    //服务导航列表
    const servicelist = ref('')

    const emergingList = ref()

    //顶部导航列表
    const toplist = ref('')

    //获取顶部导航
    const getTopNav = async () => {
        try{
            const result = await get('/nav/get_top_nav', {})
            // console.log('顶部导航', result)
            if(result.code == 1){
                toplist.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //获取所有导航
    const getAllNav = async () => {
        try{
            const result = await get('/nav/get_all_nav', {})
            // console.log('所有导航', result)
            if(result.code == 1){
                list.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //获取服务导航
    const getServiceNav = async () => {
        try{
            const result = await get('/nav/get_service', {})
            // console.log('服务导航', result)
            if(result.code == 1){
                servicelist.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //导航跳转
    const changeNav = (page) => {
        
    }

    //获取当前url
    // const url = reactive({urlLink: router.currentRoute._rawValue.fullPath})
    const url = reactive({urlLink: router.currentRoute._rawValue.matched[0].path})
    return { list, toplist, servicelist, url, getAllNav, getServiceNav, getTopNav }
}

//手机端导航处理函数
const phoneNavListEffect = () => {
    //整个导航的高度
    const headerRefHeight = ref('')

    //导航蓝色部分的高度
    const headerSmallHeight = ref('')

    //导航栏是否显示
    const smallNavShow = ref(false)

    //导航二级返回值
    const secondNavValue = ref('全部')

    //导航二级标题值
    const NavTitleValue = ref('')

    //导航二级标题index
    const NavTitleIndex = ref(0)

    //导航二级标题index
    const NavTitleSecondIndex = ref(0)

    //一级移动的值
    const FirstTranslateX = ref(0)

    //二级移动的值
    const SecondTranslateX = ref(320)

    //三级移动的值
    const ThirdTtranslateX = ref(640)

    //导航三级返回值
    const thirdNavValue = ref('')

    //导航三级标题index
    const NavThirdTitleIndex = ref(0)

    //高度赋值
    const handleHeaderNav = (number, number_two) => {
        // // console.log(number_two)
        headerRefHeight.value = number
        headerSmallHeight.value = number_two
    }

    //点击是否显示导航栏
    const handleNavShow = () => {
        smallNavShow.value = !smallNavShow.value
        if(smallNavShow.value == true){
            document.querySelector('body').setAttribute('style', 'overflow: hidden;')
        }else{
            document.querySelector('body').setAttribute('style', 'overflow: auto;')

            FirstTranslateX.value = 0

            SecondTranslateX.value = 320

            ThirdTtranslateX.value = 640
        }
    }

    //跳转二级
    const gotoSecond = (name, index) => {
        NavTitleValue.value = name

        NavTitleIndex.value = index 

        FirstTranslateX.value = -320

        SecondTranslateX.value = 0

        document.getElementsByClassName("navSecond")[0].scrollTop = 0

    }

    //点击跳转回一级
    const handleNavBack = () => {
        FirstTranslateX.value = 0

        SecondTranslateX.value = 320

        
    }

    //跳转三级导航
    const gotoThird = (name, index) => {
        thirdNavValue.value = name

        NavTitleSecondIndex.value = index

        FirstTranslateX.value = -640

        SecondTranslateX.value = -320

        ThirdTtranslateX.value = 0

        document.getElementsByClassName("navThrid")[0].scrollTop = 0

    }

    //点击跳转二级
    const handleNavBackSecond = () => {
        FirstTranslateX.value = -320

        SecondTranslateX.value = 0

        ThirdTtranslateX.value = 320

        document.getElementsByClassName("navSecond")[0].scrollTop = 0
        
    }


    return{ 
            headerRefHeight, 
            headerSmallHeight, 
            handleHeaderNav, 
            smallNavShow, 
            handleNavShow, 
            secondNavValue, 
            gotoSecond, 
            FirstTranslateX, 
            SecondTranslateX, 
            NavTitleValue, 
            NavTitleIndex, 
            handleNavBack, 
            ThirdTtranslateX, 
            NavTitleSecondIndex, 
            gotoThird,
            thirdNavValue,
            handleNavBackSecond,
        }
}


export default {
    name: 'Header',
    props: [ 'isShow', 'getHeight' ],
    emits:["getHeight"],
    setup(props,{emit}) {

        const router = useRouter()

        //导航处理函数
        const { 
            list, 
            toplist,
            servicelist,
            url, 
            getAllNav,
            getTopNav,
            getServiceNav,
        } = navListEffect()

        //获取导航的高度
        const headerRef = ref(null)

        //获取导航蓝色部分的高度
        const headerSmallRef = ref(null)

        //手机端导航处理函数
        const { 
            headerRefHeight, 
            headerSmallHeight, 
            handleHeaderNav, 
            smallNavShow, 
            handleNavShow, 
            secondNavValue, 
            gotoSecond, 
            FirstTranslateX, 
            SecondTranslateX, 
            NavTitleValue, 
            NavTitleIndex, 
            handleNavBack, 
            ThirdTtranslateX, 
            NavTitleSecondIndex,
            gotoThird,
            thirdNavValue,
            handleNavBackSecond,
        } = phoneNavListEffect()

        const searchValue = ref('')  //搜索框的值

        //回车搜索
        const onSubmit = () => {
            router.push({ path: `/search/${searchValue.value}` })
        }

        onMounted(() => {
            // console.dir( headerRef.value)
            emit('getHeight', headerRef.value.clientHeight)
            handleHeaderNav(headerRef.value.clientHeight, headerSmallRef.value.clientHeight)
            //获取所有导航
            getAllNav()
            //获取服务导航
            getServiceNav()
            // 获取顶部导航
            getTopNav()
            
        })

        return { 
            searchValue,
            list, 
            toplist, 
            servicelist,
            onSubmit,
            url, 
            headerRef, 
            headerRefHeight, 
            headerSmallHeight, 
            headerSmallRef, 
            smallNavShow, 
            handleNavShow, 
            secondNavValue, 
            gotoSecond, 
            FirstTranslateX, 
            SecondTranslateX, 
            NavTitleValue, 
            NavTitleIndex, 
            handleNavBack, 
            ThirdTtranslateX, 
            NavTitleSecondIndex,
            gotoThird,
            thirdNavValue,
            handleNavBackSecond,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 电脑端导航 */
.wraper{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    @media screen and (max-width: 1200px){
        display: none;
    }
    .top_box_content{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .top_box{
        background: $content-backgroundcolor;
        &_content{
            text-align: right;
            a{
                color:  rgba(244, 244, 244, 100);
                font-size: 12px;
                padding: 12px 0;
                text-decoration: none;
                margin-right: 20px;
                display: inline-block;
                cursor: pointer;
            }
            .search{
                /* display: inline-block; */
                margin: 12px 0;
                background-color: #fff;
                padding: 0 12px;
                display: flex;
                align-items: center;
                input{
                    width: 100px;
                    display: inline-block;
                    border: none;
                    outline: none;
                    font-size: 12px;
                    line-height: inherit;
                }
                .text{
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    margin-left: 15px;
                    color: rgb(161, 161, 161);
                    cursor: pointer;
                    font-size: 12px;
                    margin-right: 0;
                    padding: 0;
                }
                
            }
        }
    }
    .under_box{
        background-color: #fff;
        height: 70px;
        &_content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            /* padding: 10px 0; */
            &_logo{
                width: 163px;
                height: 50px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            &_nav{
                &_item{
                    position: relative;
                    display: inline-block;
                    width: 120px;
                    &:hover .second_navigation{
                        top: 70px;
                    }
                    &:hover .second_navigation_two{
                        top: 70px;
                    }
                    .title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        /* font-weight: 600; */
                        cursor: pointer;
                        line-height: 70px;
                        text-align: center;
                         &:hover{
                            color: $content-backgroundcolor;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    .second_navigation{
                        position: absolute;
                        top: -900px;
                        /* display: none; */
                        background: #fff;
                        color: #000;
                        width: max-content;
                        transition: .3s all;
                        z-index: -1;
                        .name{
                            font-size: 16px;
                            padding: 15px 30px;
                            display: block;
                            cursor: pointer;
                            text-align: center;
                            color: #333;
                            &:hover{
                                color: #fff;
                                background: $content-backgroundcolor;
                            }
                        }
                    }
                    .second_navigation_two{
                        position: absolute;
                        display: flex;
                        top: -900px;
                        left: -540%;
                        background: #fff;
                        color: #000;
                        transition: .3s all;
                        z-index: -1;
                        @media screen and (max-width: 1440px) and (min-width: 1200px){
                            left: -450%;
                        }
                        @media screen and (min-width: 1441px){
                            left: -650%;
                        }

                        .boxwidth0{
                            width: 400px;
                            padding: 15px 30px;

                            @media screen and (max-width: 1440px) and (min-width: 1200px){
                                width: 230px;
                                padding: 15px 20px;
                            }
                        }.boxwidth1{
                            width: 400px;
                            padding: 15px 30px;

                            @media screen and (max-width: 1440px) and (min-width: 1200px){
                                width: 300px;
                                padding: 15px 20px;
                            }
                        }.boxwidth2{
                            width: 400px;
                            padding: 15px 30px;

                            @media screen and (max-width: 1440px) and (min-width: 1200px){
                                width: 340px;
                                padding: 15px 20px;
                            }
                        }.boxwidth3{
                            width: 400px;
                            padding: 15px 30px;

                            @media screen and (max-width: 1440px) and (min-width: 1200px){
                                width: 250px;
                                padding: 15px 20px;
                            }
                        }
                        .one{
                            &:nth-of-type(2){
                                .box_name{
                                    width: calc(100% / 2)!important;
                                }
                            }
                            .one_title{
                                color: rgba(48, 90, 168, 100);
                                font-size: 18px;
                                display: block;
                                transition: .3s all;
                                padding-left: 15px;
                                line-height: 30px;
                                &:hover{
                                    background-color: rgba(48, 90, 168, 100);
                                    color: #fff;
                                }
                            }
                            .line{
                                width: 100%;
                                height: 1px;
                                background: #bbb;
                                margin-top: 5px;
                            }
                            .box{
                                width: 100%;
                                margin-top: 15px;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                .box_name{
                                    width: calc(100% / 2);
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #101010;
                                    text-align: left;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    /* display: inline-block;
                                    margin-right: 30px; */
                                    p{
                                        line-height: 30px;
                                    }
                                    &:hover{
                                        color: #fff;
                                        background: $content-backgroundcolor;
                                    }
                                }
                                &::after{
                                    content: '';
                                    width: calc(100% / 3.3);
                                }
                                .box_name_two{
                                    width: 100%;
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #101010;
                                    text-align: left;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    /* display: inline-block;
                                    margin-right: 30px; */
                                    p{
                                        line-height: 30px;
                                    }
                                    &:hover{
                                        color: #fff;
                                        background: $content-backgroundcolor;
                                    }
                                }
                            }
                        }
                    }
                    .active{
                        color: $content-backgroundcolor;
                    }
                }
            }
        }
    }
}
.isShow{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: rgb(0 0 0 / 10%) 1px 1px 1px 1px;
    animation: show .5s;
    display: block;
    @media screen and (max-width: 1200px){
        display: none;
    }
    @keyframes show {
        0% {
            opacity: 0;
            top: -100px;
        }
        100% {
            opacity: 1;
            top: 0px;
        }
    }
}
.isHide{
    animation: hide .5s;
    @media screen and (max-width: 1200px){
        display: none;
    }
    @keyframes hide {
        0% {
            width: 100%;
            position: fixed;
            top: 0;
            opacity: 1;
            z-index: 99;
        }
        100% {
            width: 100%;
            position: fixed;
            top: -30px;
            opacity: 0;
            z-index: 99;
            display: none;
        }
    }
}
/* 手机端导航 */
.small_nav_box{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: none;
    background: #fff;
    @media screen and (max-width: 1200px){
        display: block;
    }
    .small_nav{
        .top_box{
            background: $content-backgroundcolor;
            padding: 0 30px;
            &_content{
                text-align: right;
                a{
                    color:  rgba(244, 244, 244, 100);
                    font-size: 12px;
                    padding: 12px 0;
                    text-decoration: none;
                    margin-right: 20px;
                    display: inline-block;
                    cursor: pointer;
                    /* &:nth-child(4){ */
                    &:nth-last-child(1){
                        margin-right: 0px;
                    }
                }
            }
        }
        .under_box{
            padding: 8px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            .left{
                img{
                    width: 70%;
                }
            }
            .right{
                p{
                    font-size: 32px;
                    margin-top: -4px;
                }
                .icon_img{
                    width: 33px;
                    height: 28px;
                }
            }
        }
        .select_nav_back{
            width: 100%;
            height: 100vh;
            position: absolute;
            right: 0;
            left: 0;
            z-index: 11;
            opacity: 1;
            visibility: visible;
            transition: opacity .8s ease,visibility 0s;
            background-color: hsla(0,0%,7%,.36);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
        }
        .select_nav_box{
            width: 320px;
            height: 100vh;
            background: #fff;
            position: absolute;
            right: -1000px;
            z-index: 11;
            transition: .3s all;
            padding: 26px 0 150px 0;
            overflow-x: hidden;

            .icon_img{
                width: 20px;
                height: 20px;
            }
            .mobilemenu{
                .first{
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100vh;
                    transition: .3s all;
                    overflow-y: auto;
                    padding: 26px 0 150px 0;
                    /* transform: translateX(0px); */
                    .item{
                        padding: 10px 36px;
                        div{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .title{
                                font-size: 14px;
                                color: #333;
                            }
                            .icon{
                                font-size: 14px;
                                color: #848484;
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
                .second{
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100vh;
                    transition: .3s all;
                    overflow-y: auto;
                    padding: 26px 0 150px 0;
                    .top{
                        padding: 0 36px;
                        display: flex;
                        align-items: center;
                        .icon{
                            font-size: 16px;
                            color: #848484;
                        }
                        .title{
                            font-size: 16px;
                            color: #848484;
                            padding-left: 16px;
                        }
                    }
                    .nav_title{
                        margin-top: 40px;
                        color: #333;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 0 36px;
                    }
                    .second_list_box{
                        margin-top: 40px;
                        padding: 0 0 350px 0;
                        .second_list{
                            padding: 10px 36px;
                            div{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .title{
                                    font-size: 14px;
                                    color: #757575;
                                }
                                .icon{
                                    font-size: 16px;
                                    color: #757575;
                                    transform: rotate(-180deg);
                                }
                            }
                            
                        }
                    }
                }
                .thrid{
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100vh;
                    transition: .3s all;
                    .top{
                        padding: 0 36px;
                        display: flex;
                        align-items: center;
                        .icon{
                            font-size: 16px;
                            color: #848484;
                        }
                        .title{
                            font-size: 16px;
                            color: #848484;
                            padding-left: 16px;
                        }
                    }
                    .nav_title{
                        margin-top: 40px;
                        color: #333;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 0 36px;
                    }
                    .thrid_list_box{
                        margin-top: 40px;
                        padding: 0 0 350px 0;
                        .thrid_list{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px 36px;
                            .title{
                                font-size: 14px;
                                color: #757575;
                            }
                            .icon{
                                font-size: 16px;
                                color: #757575;
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }
        }
        .select_nav_box_active{
            right: 0px;
            transition: .3s all;
        }
    }
}
</style>
